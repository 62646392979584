
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <page-title></page-title>    
          <br>
          <div class="sub-card"  >
            <div class="section-title">
              <span >ご挨拶</span>
            </div>
            <div class="section-main">
              <span>
                <br><br>
                2014年9月に株式会社MIX YARD は生まれました。<br>
                昨今の目まぐるしく動く激動の世界の中で、いつまでも日本だけが飽食の時代を過ごしていけるのでしょうか？<br><br>
                どこかで『モノ』を粗末に扱い、『モノの大切さ』をどこか忘れてしまっている日本人が多く存在している現実。<br>
                私たちの心の奥底で無意識に生まれている傲慢な態度。そんな世の中だからこそMIXYARDは、ただ単に『モノ』を扱うという事ではなく、 あくまでも『ヒト』が心を込めてモノを扱う。それをわが社のポリシーとして一人一人が作業することを心掛けています。<br>
                お客様のニーズに応えることは商売としては極々当たり前の事です。<br>
                そこに『信頼』と『感謝』をプラスしてこそ初めてサービスをいう言葉の意義が出てくると思います。<br><br>
                まだまだ地方の小さな会社ではありますが、世界物流の一端を担う立場として『信頼/感謝』とともに、 お客様にそして社会に貢献していきたいと考えております。<br><br>
              </span>
              <span style="float: right;">代表取締役 前川 裕</span>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >会社概要</span>
            </div>
            <div class="section-main">
               <div v-for="(profile, i) in profiles" :key="i" class="access-list " uk-grid>
                  <div class="uk-width-1-4@m" style="" uk-leader>{{profile.title}}</div>
                  <div class="uk-width-3-4@m">{{profile.detail}}</div>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >日産GT-Rの中古車をお探しならcar shop MIXへ</span>
            </div>
            <div class="section-main">
              <span>
                <br><br>
                日産GT-Rの中古車をお探しの方は、car shop MIXにご相談ください。R32・R33・R34・R35・ニスモなど、スカイラインGT-Rシリーズの人気車種はもちろん、メルセデスAMG、ポルシェ、ランボルギーニなど、国産車・外車を問わず、MT車もAT車も販売しております。<br>
                <a href="https://www.worldingsysdom.com/stock_info/list/car" target="_blank" rel="noopener noreferrer nofollow" class="seo-l">在庫</a>
                がない場合も、オークションの代行などによりご希望の車をお探しすることが可能です。低走行の車やなかなか市場に出回らない希少な車などもご用意することができますので、まずはお気軽にお問い合わせください。
                <div class="c-btn">
                  <router-link to="/jigyo/oldCars" class="c-btn-anchor">car shop MIXの中古車について</router-link>
                  <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>

export default {
  
  data: () => ({
    profiles:[]
  }),
  created() {
    // メインメニュー作成
    this.profiles = this.commonSetting.companyProfile;
  },
}
</script>



<style scoped lang="scss">

.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
}

.seo-l {color: #c49a00;border-bottom: 1px solid #c49a00;transition: .3s;padding: 0 2px;}
.seo-l:hover {color: #929292;border-bottom: transparent;}

/*ボタン PCのみ*/
@media (min-width: 768px) {
  .c-btn-anchor {padding: 15px 20px;width: 25%;}
  .c-btn .c-btn-anchor:nth-child(2) {margin: 0 0 0 35px;}
}
</style>
